<template>
  <Login 
    emailText="Sign in with Email"
    title="Sign in"
    btnText="Sign in"
    :isLogin="true"
  />
</template>

<script>
  import Login from '@/components/login/Login'
  export default {
    components: { Login }
  }
</script>

<style lang="scss" scoped>

</style>